<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by company name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="addCompany()"
              v-on="on"
              data-testid="add-company"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Company</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <CompanyFilter
          :organizations="orgsForFilter"
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="exportCompanies()"
              v-on="on"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export Companies</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-blur
                  class="msaBlue white--text"
                  v-bind="attrs"
                  v-on="{ ...menu, ...tooltip }"
                >
                  <v-icon> mdi-microsoft-excel </v-icon>
                </v-btn>
              </template>
              <span>Download usage reports</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              @click="downloadUsageReport('usage-report', 'usage_report.xlsx')"
            >
              Usage reports
            </v-list-item>
            <v-list-item
              @click="
                downloadUsageReport('course-report', 'course_report.xlsx')
              "
            >
              Course report
            </v-list-item>
            <v-list-item @click="downloadUserSyncsReport">
              User syncs report
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Companies
            <v-badge
              color="msaBlue"
              bordered
              :content="orgCount"
              :value="orgCount"
              offset-y="10"
              class="ml-4"
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="orgs"
            :server-items-length="orgCount"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :options.sync="options"
            v-if="$vuetify.breakpoint.mdAndUp"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr>
                <td :class="`${!!item.status ? 'msaBlue' : 'red'}--text`">
                  <span @click="goToDetail(item)" style="cursor: pointer">
                    {{ item.companyName }}
                    <br />
                    <span class="grey--text text-caption">
                      {{ item.groupName }}
                    </span>
                  </span>
                </td>
                <td align="center">
                  {{ item.licenses }}
                </td>
                <td align="center" class="account-type">
                  <div :class="getAccountTypeStatus(item)">
                    <span class="white--text">{{ item.accountType }}</span>
                  </div>
                </td>
                <td class="actions">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="msaBlue"
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="goToDetail(item)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      v-if="$vuetify.breakpoint.smAndDown"
      hide-default-header
      :items="orgs"
      :server-items-length="orgCount"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      :options.sync="options"
      must-sort
    >
      <template v-slot:item="{ item }">
        <v-card class="my-1">
          <v-card-title
            class="msaBlue white--text pt-2"
            @click="goToDetail(item)"
          >
            <v-row>
              <v-col class="text-subtitle-1">
                {{ item.companyName }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle class="text-subtitle-1 msaBlue grey--text pb-2">
            <v-row>
              <v-col class="py-0">
                {{ item.groupName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="account-type text-center text-subtitle-2">
                <div :class="getAccountTypeStatus(item)">
                  <span class="white--text">{{ item.accountType }}</span>
                </div>
              </v-col>
              <v-col align="right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="msaBlue"
                      text
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="goToDetail(item)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import CompanyFilter from '@/components/Filters/CompanyFilter.vue';
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'CompanyList',
  components: {
    CompanyFilter,
    SearchBar,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      showFilterDialog: false,
      orgs: [],
      orgCount: 0,
      orgsForFilter: [],
      options: {
        sortBy: ['companyName'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'companyName',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Licenses',
          value: 'licenses',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
          sortable: false,
        },
        {
          text: 'Account Type',
          value: 'accountType',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '175px',
        },
        {
          text: 'Actions',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '93px',
          sortable: false,
        },
      ],
      filters: {
        ...this.$constants.COMPANY_LIST_DEFAULT_FILTERS,
      },
    };
  },
  methods: {
    downloadUsageReport(type, filename) {
      const params = {
        options: this.options,
        filters: this.filters,
        type,
        loaderText: 'Generating report...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post('download-usage-report?format=xlsx', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
    downloadUserSyncsReport() {
      const params = {
        loaderText: 'Generating report...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post('download-user-syncs-report?format=xlsx', params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'user_syncs_report.xlsx');
        })
        .catch((error) => error);
    },
    getCompanies() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
      };
      this.$axios
        .post('get-companies?format=json', params)
        .then((response) => {
          this.orgs = response.data.items;
          this.orgCount = response.data.count;
          this.getCompaniesForFilter();
        })
        .catch((error) => error);
    },
    getCompaniesForFilter() {
      this.$axios
        .post('get-companies-for-filter?format=json', {})
        .then((response) => {
          this.orgsForFilter = response.data.filter(
            (company) => company.Status === 'Active',
          );
        })
        .catch((error) => error);
    },
    getAccountTypeStatus(company) {
      switch (company.accountType.toLowerCase()) {
        case 'basic':
          return 'subscription';
        case 'a la carte':
          return 'aLaCarte';
        case 'free trial':
          return 'freetrial';
        case 'freemium':
          return 'freemium';
        case 'basic (old)':
          return 'basicOld';
        case 'peace of mind':
          return 'peaceOfMind';
        case 'freedom to operate':
          return 'freedomToOperate';
        case 'all access':
          return 'allAccess';
        case 'basic (new)':
          return 'basicNew';
        case 'test account':
          return 'testAccount';
        case 'training':
          return 'training';
        case 'training plus':
          return 'trainingPlus';
        default:
          return '';
      }
    },
    applyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;
      this.updateUrl();
    },
    goToDetail(company) {
      this.$router.push({
        name: 'CompanyDetail',
        params: { companyId: company.companyId },
      });
    },
    onSearch(event) {
      this.filters.search = event;
      this.options.page = 1;
      this.updateUrl();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.COMPANY_LIST_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'companyName',
        false,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'companyName');

      this.getCompanies();

      if (this.filters.search != '') {
        this.$refs.searchBar.search = this.filters.search;
      }
      this.$refs.filters.setFilters();
    },
    addCompany() {
      this.$store.commit('updateSelectedCompany', {
        id: -1,
        name: 'New Company',
        status: true,
      });
      this.$router.push({
        name: 'CompanyDetail',
        params: { companyId: -1 },
      });
    },
    exportCompanies() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Exporting...',
      };

      const url = 'export-companies?format=json';

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post(url, params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Company Export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.search != '') {
        this.$refs.searchBar.search = this.filters.search;
      }

      this.$refs.filters.setFilters();
    });
  },
};
</script>
<style>
.account-type div {
  border-radius: 5px;
}
</style>
